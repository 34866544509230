import { GetStaticProps } from 'next';
import { useEffect, useState } from 'react';
import { QueryClient } from '@tanstack/react-query';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import ContentBlock from '@components/content-block/ContentBlock';
import Seo from '@components/seo/seo';
import Layout from '@components/layout/layout';
import Container from '@components/ui/container';
import HomeController from 'src/controller/home-controller';
import commonSiteData from '@utils/commonSiteData';
import homePageMapper from '@utils/mappers/home/home-page-mapper';

interface PageProps {
  readonly pageData: any;
  readonly draftMode: boolean;
}

export default function Home({ pageData, draftMode }: PageProps) {
  const [updatedPageData, setUpdatedPageData] = useState(pageData.data ?? []);
  const liveUpdates = useContentfulLiveUpdates(pageData.dataRaw);

  useEffect(() => {
    if (!draftMode) return;

    (async () => {
      const data = (await homePageMapper(liveUpdates)) ?? [];
      setUpdatedPageData(data);
    })();
  }, [liveUpdates, draftMode]);

  if (!pageData) return null;

  return (
    <>
      <Seo
        title="WTC Demo store"
        description="WTC Demo store build with NextJS"
        path="/"
      />

      <Container>
        {updatedPageData?.map((data: any, id: number) => {
          return (
            <ContentBlock key={id} isLoading={false} error={null} data={data} />
          );
        })}
      </Container>
    </>
  );
}

Home.Layout = Layout;

export const getStaticProps: GetStaticProps = async ({
  locale,
  draftMode = false,
}) => {
  const queryClient = new QueryClient();

  const pageData = await queryClient.fetchQuery(
    ['homeData', { draftMode }],
    HomeController.HomepageContent.fetch,
  );

  const commonData = await commonSiteData(queryClient);

  return {
    props: {
      pageData: JSON.parse(JSON.stringify(pageData)),
      ...commonData,
      ...(await serverSideTranslations(locale!, [
        'common',
        'forms',
        'menu',
        'footer',
        'search',
      ])),
      draftMode,
    },
    revalidate: 60,
  };
};
