import {
  ComponentNameMapper,
  AvailableComponents,
} from '@utils/getComponentProps';

type ContentBlockProps = {
  data: [AvailableComponents, any] | null;
  error: Error | null;
  isLoading: boolean;
};

const ContentBlock = (props: ContentBlockProps) => {
  const { data, isLoading, error } = props;
  const [componentName, componentData] = data ?? [];

  if (!componentName) return null;

  const Component = ComponentNameMapper[componentName];

  if (!Component) return null;

  return <Component {...componentData} isLoading={isLoading} error={error} />;
};

export default ContentBlock;
